import React from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    TextInput,
    useRecordContext,
    DeleteWithConfirmButton,
    TranslatableInputs,
    SelectInput,
    BooleanInput,
    BooleanField,
    required,
} from "react-admin";

const PostTitle = () => {
    const record = useRecordContext();
    return <span>Edit Subcategory: {record ? `#${record.id} ${record.title.de}` : ''}</span>;
};

const filters = [
    <TextInput source="title.de" label="Search (title DE)" alwaysOn />,
    <TextInput source="id" label="ID" />,
    <ReferenceInput source="categoryId" label="Category" reference="categories" />,
    <BooleanInput source="show" label="Show" defaultValue={true} />
];

export const SubCategoriesList = (props) => (
    <List filters={filters} {...props}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="categoryId" reference="categories">
                <TextField source="title.de" />
            </ReferenceField>
            <TextField source="title.de" label="Title" />
            <BooleanField source="show" />
            <EditButton />
            <DeleteWithConfirmButton />
        </Datagrid>
    </List>
);

export const SubCategoriesEdit = (props) => (
    <Edit title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput source="id" InputProps={{ disabled: true }} />
            <ReferenceInput source="categoryId" reference="categories">
                <SelectInput source="id" optionText="title.de" />
            </ReferenceInput>
            <TranslatableInputs locales={['de', 'en', 'fr', 'pl', 'tr', 'es', 'it', 'ru']} defaultLocale="de">
                <TextInput source="title" fullWidth />
            </TranslatableInputs>
            <BooleanInput source="show" />
        </SimpleForm>
    </Edit>
);

export const SubCategoriesCreate = (props) => (
    <Create redirect="/subcategories" {...props}>
        <SimpleForm>
            <ReferenceInput source="categoryId" reference="categories">
                <SelectInput source="id" optionText="title.de" validate={required()} />
            </ReferenceInput>
            <TranslatableInputs locales={['de', 'en', 'fr', 'pl', 'tr', 'es', 'it', 'ru']} defaultLocale="de">
                <TextInput source="title" fullWidth />
            </TranslatableInputs>
            <BooleanInput source="show" defaultValue={true} />
        </SimpleForm>
    </Create>
);