import { withLifecycleCallbacks } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { httpClient } from './httpClient';
import { processMedia } from '../utils/media';

const MODE = process.env.REACT_APP_MODE;
const apiDomain = `${process.env[`REACT_APP_API_URL_${MODE}`]}/api`;

const dataProvider = withLifecycleCallbacks(simpleRestProvider(apiDomain, httpClient), [
// const dataProvider = withLifecycleCallbacks(simpleRestProvider('https://api.restaurant-saperavi.com/api', httpClient), [
        {
        resource: 'menu',
        beforeCreate: async (params) => {
            return await processMedia(params);
        },
        beforeUpdate: async (params) => {
            if (typeof(params.data.media) == 'object') {
                return await processMedia(params);
            }
            return params;
        }
    }
]);

export default dataProvider;