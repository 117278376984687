/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });

export const processMedia = async (params) => {
    const newMedia = params.data.media;

    const base64Media = await convertFileToBase64(newMedia);

    const media = {
        base64: base64Media,
        type: newMedia.rawFile.type,
    };
    
    return {
        ...params,
        data: {
            ...params.data,
            media,
        }
    };
};
