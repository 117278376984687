import axios from "axios";

const MODE = process.env.REACT_APP_MODE;
const apiDomain = `${process.env[`REACT_APP_API_URL_${MODE}`]}/api/users`;

const authProvider = {
    login: async ({ username, password }) =>  {
        const body = { username, password }
        
        try {
            const response = await axios.post(`${apiDomain}/login`, body);
            // headers: new Headers({ 'Content-Type': 'application/json' })
            
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.message);
            }
            
            const { token } = response.data;

            if (response.status === 200 && token) {
                localStorage.setItem('auth', JSON.stringify(token));
            }
        } catch (error) {
            throw new Error('Network error');
        }
    },
    
    // checkError: (error) => {
    //     const status = error.status;
    //     if (status === 401 || status === 403) {
    //         localStorage.removeItem('auth');
    //         return Promise.reject({ message: false });
    //         //return Promise.reject({ message: 'Unauthorized user!' });
    //     }
    //     // other error code (404, 500, etc): no need to log out
    //     return Promise.resolve();
    // },

    checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject({ message: 'login.required' }),
    
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve('/login');
    },
        
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },

    getPermissions: () => Promise.resolve(''),
};

export default authProvider;