import React, { useState } from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    TextInput,
    useRecordContext,
    DeleteWithConfirmButton,
    TranslatableInputs,
    SelectInput,
    BooleanInput,
    BooleanField,
    NumberInput,
    required,
    Labeled,
    ImageInput,
    ImageField,
    DateField,
    FunctionField,
} from "react-admin";

const PostTitle = () => {
    const record = useRecordContext();
    return <span>Edit Item: {record ? `#${record.id}` : ''}</span>;
};

const CurrentImage = (props) => {
    const record = useRecordContext();
    const MODE = process.env.REACT_APP_MODE;
    return record && (
        <img 
            {...props} 
            alt={record.title.de} 
            src={`${process.env[`REACT_APP_API_URL_${MODE}`]}/uploads/${record.media}`} 
        />
    );
};

const renderWeight = () => (
    <span>
        <TextField source="weight" /> <TextField source="weightType" />
    </span>
);

const renderPrice = () => (
    <span>
        <TextField source="price" /> €
    </span>
);

export const MenuList = (props) => {
    const [ categoryFilterId, setCategoryFilterId ] = useState(0);
    const filters = [
        <TextInput source="id" label="ID" />,
        <TextInput source="title.de" label="Search (title)" alwaysOn />,
        <TextInput source="description.de" label="Search (description)" />,
        <ReferenceInput 
            source="categoryId" 
            label="Category" 
            reference="categories" 
            sort={{ field: 'id', order: 'ASC' }}
        >
            <SelectInput onChange={(e) => setCategoryFilterId(e.target.value)} />
        </ReferenceInput>,
        <ReferenceInput 
            source="subcategoryId" 
            label="Subcategory" 
            reference="subcategories" 
            sort={{ field: 'categoryId', order: 'ASC' }}
            filter={{ categoryId: categoryFilterId }}
        >
            <SelectInput />
        </ReferenceInput>,
        <BooleanInput source="show" label="Show" defaultValue={true} />
    ];
    
    return (
        <List filters={filters} {...props} title="Menu">
            <Datagrid>
                <TextField source="id" />
                <ReferenceField source="categoryId" reference="categories">
                    <TextField source="title.de" />
                </ReferenceField>
                <ReferenceField source="subcategoryId" reference="subcategories">
                    <TextField source="title.de" />
                </ReferenceField>
                <CurrentImage style={{height: '50px', width: '50px', objectFit: 'contain', objectPosition: 'center', verticalAlign: 'middle'}} />
                <TextField source="title.de" label="Title" />
                <FunctionField source="weight" label="Weight" render={renderWeight} />
                <FunctionField source="price" label="Price" render={renderPrice} />
                <BooleanField source="show" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export const MenuEdit = (props) => {
    const [ categoryFilterId, setCategoryFilterId ] = useState(0);
    return (
        <Edit title={<PostTitle />} {...props}>
            <SimpleForm>
                <TextInput source="id" InputProps={{ disabled: true }} />

                <ReferenceInput 
                    source="categoryId" 
                    reference="categories" 
                    sort={{ field: 'id', order: 'ASC' }}
                >
                    <SelectInput 
                        validate={required()} 
                        onChange={(e) => setCategoryFilterId(e.target.value)} 
                        fullWidth
                    />
                </ReferenceInput>
                
                <ReferenceInput 
                    source="subcategoryId" 
                    reference="subcategories"
                    sort={{ field: 'id', order: 'ASC' }}
                    filter={{ categoryId: categoryFilterId }}
                >
                    <SelectInput validate={required()} fullWidth />
                </ReferenceInput>
                
                <TranslatableInputs locales={['de', 'en', 'fr', 'pl', 'tr', 'es', 'it', 'ru']} defaultLocale="de" fullWidth>
                    <TextInput source="title" />
                    <TextInput source="description" multiline />
                </TranslatableInputs>
                
                <NumberInput source="price" validate={required()} />
                
                <SelectInput source="weightType" choices={[
                    { id: 'g', name: 'Gramm (g)' },
                    { id: 'l', name: 'Liter (l)' },
                    { id: 'cl', name: 'CL (cl)' },
                ]} />
                
                <NumberInput source="weight" defaultValue={null} />
                <NumberInput source="likes" defaultValue={0} validate={required()} />
                <NumberInput source="pieces" defaultValue={1} validate={required()} />
                
                <ImageInput source="media" label="Media" validate={required()} >
                    <ImageField source="src" title="title" />
                </ImageInput>

                <Labeled>
                    <CurrentImage label="Current image" style={{marginBottom: '20px', maxHeight: '200px', maxWidth: '200px'}} />
                </Labeled>

                <BooleanInput source="show" defaultValue={true} />

                <Labeled>
                    <DateField label="Created" source="createdAt" showTime />
                </Labeled>
                <Labeled>
                    <DateField label="Updated" source="updatedAt" showTime />
                </Labeled>
            </SimpleForm>
        </Edit>
    );
};

export const MenuCreate = (props) => {
    const [ categoryFilterId, setCategoryFilterId ] = useState(0);
    return (
        <Create {...props} redirect="/menu">
            <SimpleForm>
                <ReferenceInput 
                    source="categoryId" 
                    reference="categories" 
                    sort={{ field: 'id', order: 'ASC' }}
                >
                    <SelectInput 
                        validate={required()} 
                        onChange={(e) => setCategoryFilterId(e.target.value)} 
                        fullWidth
                    />
                </ReferenceInput>
                
                <ReferenceInput 
                    source="subcategoryId" 
                    reference="subcategories"
                    sort={{ field: 'id', order: 'ASC' }}
                    filter={{ categoryId: categoryFilterId }}
                >
                    <SelectInput validate={required()} fullWidth />
                </ReferenceInput>
                
                <TranslatableInputs locales={['de', 'en', 'fr', 'pl', 'tr', 'es', 'it', 'ru']} defaultLocale="de" fullWidth>
                    <TextInput source="title" />
                    <TextInput source="description" multiline />
                </TranslatableInputs>
                
                <NumberInput source="price" validate={required()} />
                
                <SelectInput source="weightType" choices={[
                    { id: 'g', name: 'Gramm (g)' },
                    { id: 'l', name: 'Liter (l)' },
                    { id: 'cl', name: 'CL (cl)' },
                ]} />
                
                <NumberInput source="weight" defaultValue={null} />
                <NumberInput source="likes" defaultValue={0} validate={required()} />
                <NumberInput source="pieces" defaultValue={1} validate={required()} />
                
                <ImageInput source="media" label="Media" validate={required()} >
                    <ImageField source="src" title="title" />
                </ImageInput>
                
                <BooleanInput source="show" defaultValue={true} />
            </SimpleForm>
        </Create>
    );
};
