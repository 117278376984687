import React from 'react';
import { Admin, Resource } from 'react-admin';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import { CategoriesList, CategoriesEdit, CategoriesCreate } from './components/Categories';
import { SubCategoriesList, SubCategoriesEdit, SubCategoriesCreate } from './components/SubCategories';
import { MenuCreate, MenuEdit, MenuList } from './components/Menu';

import CategoryIcon from '@mui/icons-material/ViewList';
import SubCategoryIcon from '@mui/icons-material/FormatListBulleted';
import MenuBookIcon from '@mui/icons-material/MenuBook';

function App() {
  return (
    <Admin
      requireAuth
      authProvider={authProvider}
      dataProvider={dataProvider}
      // dashboard={Dashboard}
    >
      <Resource
        name='categories'
        recordRepresentation="title.de"
        list={CategoriesList}
        create={CategoriesCreate}
        edit={CategoriesEdit}
        icon={CategoryIcon}
      />
      <Resource
        name='subcategories'
        recordRepresentation="title.de"
        list={SubCategoriesList}
        create={SubCategoriesCreate}
        edit={SubCategoriesEdit}
        icon={SubCategoryIcon}
      />
      <Resource
        name='menu'
        list={MenuList}
        create={MenuCreate}
        edit={MenuEdit}
        icon={MenuBookIcon}
      />
    </Admin>
  )
}

export default App
