import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    useRecordContext,
    DeleteWithConfirmButton,
    TranslatableInputs,
    BooleanField,
    BooleanInput,
} from "react-admin";

const PostTitle = () => {
    const record = useRecordContext();
    return <span>Edit Category: {record ? `#${record.id} ${record.title.de}` : ''}</span>;
};

export const CategoriesList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title.de" label="Title" />
            <BooleanField source="show" />
            <EditButton />
            <DeleteWithConfirmButton />
        </Datagrid>
    </List>
);

export const CategoriesEdit = (props) => (
    <Edit title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput source="id" InputProps={{ disabled: true }} />
            <TranslatableInputs locales={['de', 'en', 'fr', 'pl', 'tr', 'es', 'it', 'ru']} defaultLocale="de">
                <TextInput source="title" fullWidth />
            </TranslatableInputs>
            <BooleanInput source="show" />
        </SimpleForm>
    </Edit>
);

export const CategoriesCreate = (props) => (
    <Create redirect="/categories" {...props}>
        <SimpleForm>
            <TranslatableInputs locales={['de', 'en', 'fr', 'pl', 'tr', 'es', 'it', 'ru']} defaultLocale="de">
                <TextInput source="title" fullWidth />
            </TranslatableInputs>
            <BooleanInput source="show" defaultValue={true} />
        </SimpleForm>
    </Create>
);